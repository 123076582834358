import React, { useState, useEffect } from 'react';
import './Subscription.scss';

const Subscription = () => {
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchSubscription();
  }, []);

  const fetchSubscription = async () => {
    try {
      const response = await fetch('/api/subscriptions/user/current');
      if (!response.ok) {
        throw new Error('Failed to fetch subscription data');
      }
      const data = await response.json();
      setSubscription(data);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const toggleAddOn = async (addOn) => {
    try {
      const response = await fetch(`/api/subscriptions/${subscription.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          [`add_on_${addOn}`]: !subscription[`add_on_${addOn}`]
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to update add-on');
      }
      const data = await response.json();
      setSubscription(data);
    } catch (err) {
      setError(err.message);
    }
  };

  const cancelSubscription = async () => {
    if (window.confirm('Are you sure you want to cancel your subscription? This will be effective at the end of your current billing period.')) {
      try {
        const response = await fetch(`/api/subscriptions/${subscription.id}/cancel`, {
          method: 'PUT',
        });
        if (!response.ok) {
          throw new Error('Failed to cancel subscription');
        }
        const data = await response.json();
        setSubscription(data);
      } catch (err) {
        setError(err.message);
      }
    }
  };

  if (loading) return <div className="subscription loading">Loading...</div>;
  if (error) return <div className="subscription error">{error}</div>;
  if (!subscription) return <div className="subscription error">No subscription found</div>;

  return (
    <div className="subscription">
      <h2>Your Subscription</h2>
      <div className="subscription-details">
        <p><strong>Talexa Subscription:</strong> {subscription.talexa_subscription ? 'Active' : 'Inactive'}</p>
        <p><strong>Type:</strong> {subscription.type}</p>
        <p><strong>Status:</strong> {subscription.status}</p>
        <p><strong>Start Date:</strong> {new Date(subscription.start_date).toLocaleDateString()}</p>
        <p><strong>End Date:</strong> {new Date(subscription.end_date).toLocaleDateString()}</p>
        <p><strong>Price:</strong> ${subscription.price}</p>
        <p><strong>Payment Type:</strong> {subscription.payment_type}</p>
        <p><strong>Payment Term:</strong> {subscription.payment_term}</p>
      </div>

      <h3>Add-ons</h3>
      <div className="add-ons">
        <div className="add-on">
          <label>
            <input
              type="checkbox"
              checked={subscription.talexa_subscription}
              onChange={() => toggleAddOn('talexa_subscription')}
            />
            Talexa Subscription
            <p>{subscription.talexa_subscription ? 'Active' : 'Inactive'}</p>
          </label>
        </div>
        <div className="add-on">
          <label>
            <input
              type="checkbox"
              checked={subscription.add_on_analysis}
              onChange={() => toggleAddOn('analysis')}
            />
            Analysis
            <p>{subscription.add_on_analysis ? 'Active' : 'Inactive'}</p>
          </label>
        </div>
        <div className="add-on">
          <label>
            <input
              type="checkbox"
              checked={subscription.add_on_skills_assessment}
              onChange={() => toggleAddOn('skills_assessment')}
            />
            Skills Assessment
            <p>{subscription.add_on_skills_assessment ? 'Active' : 'Inactive'}</p>
          </label>
        </div>
        <div className="add-on">
          <label>
            <input
              type="checkbox"
              checked={subscription.add_on_language_assessment}
              onChange={() => toggleAddOn('language_assessment')}
            />
            Language Assessment
            <p>{subscription.add_on_language_assessment ? 'Active' : 'Inactive'}</p>
          </label>
        </div>
        <div className="add-on">
          <label>
            <input
              type="checkbox"
              checked={subscription.add_on_white_label}
              onChange={() => toggleAddOn('white_label')}
            />
            White Label
            <p>{subscription.add_on_white_label ? 'Active' : 'Inactive'}</p>
          </label>
        </div>
        <div className="add-on">
          <label>
            <input
              type="checkbox"
              checked={subscription.add_on_ai_phone_call}
              onChange={() => toggleAddOn('ai_phone_call')}
            />
            AI Phone Call
            <p>{subscription.add_on_ai_phone_call ? 'Active' : 'Inactive'}</p>
          </label>
        </div>
      </div>

      {subscription.status !== 'Cancellation Pending' && (
        <button className="cancel-subscription" onClick={cancelSubscription}>
          Cancel Subscription
        </button>
      )}

      {subscription.status === 'Cancellation Pending' && (
        <p className="cancellation-notice">
          Your subscription will be cancelled at the end of the current billing period.
        </p>
      )}
    </div>
  );
};

export default Subscription;
