const API_BASE_URL = 'http://localhost:3000'; 

export const login = async (email, password) => {
  try {
    const response = await fetch('/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
      credentials: 'include', // important for sending/receiving cookies
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Login failed');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

export const logout = () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('tenantId');
};

export const checkAuthStatus = async () => {
  try {
    const token = localStorage.getItem('jwt');
    if (!token) {
      return { isAuthenticated: false };
    }

    const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/verify`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });

    if (!response.ok) {
      localStorage.removeItem('jwt');
      return { isAuthenticated: false };
    }

    const data = await response.json();
    return {
      isAuthenticated: true,
      userAddOns: data.userAddOns
    };
  } catch (error) {
    console.error('Auth check failed:', error);
    return { isAuthenticated: false };
  }
};

export const setAuthHeader = (token) => {
  if (token) {
    sessionStorage.setItem('token', token);
  } else {
    sessionStorage.removeItem('token');
  }
};
