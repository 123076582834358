const API_URL = process.env.REACT_APP_API_URL;

export const getAllJobOffers = async () => {
  const response = await fetch(`${API_URL}/api/job-offers`, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('token')}`
    }
  });
  if (!response.ok) {
    throw new Error('Failed to fetch job offers');
  }
  return response.json();
};

export const getJobBoard = async () => {
  const response = await fetch(`${API_URL}/api/job-board`);
  if (!response.ok) {
    throw new Error('Failed to fetch job board');
  }
  return response.json();
};

export const getSearchJobs = async (searchParams) => {
  const response = await fetch(`${API_URL}/api/search-jobs?${searchParams}`);
  if (!response.ok) {
    throw new Error('Failed to search job offers');
  }
  return response.json();
};
