import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './B2CDashboardHeader.scss';
import { logError } from '../../../services/errorLogging'; // Importez la fonction logError

const DashboardHeader = ({ userAddOns }) => {
  const { t } = useTranslation();
  const [activeDropdown, setActiveDropdown] = useState(null);
  const navigate = useNavigate();

  const toggleDropdown = (menu) => {
    try {
      setActiveDropdown(activeDropdown === menu ? null : menu);
    } catch (error) {
      logError(error); // Utilisez logError ici
    }
  };

  const handleLogout = () => {
    try {
      // Supprimez les informations de l'utilisateur du sessionStorage
      sessionStorage.removeItem('user');
      // Supprimez le token d'authentification (si vous en utilisez un)
      localStorage.removeItem('authToken');
      // Redirigez l'utilisateur vers la page de connexion
      navigate('/login');
    } catch (error) {
      logError(error);
    }
  };

  const menuItems = [
    {
      name: 'myAccount',
      dropdown: ['myProfile', 'subscriptionAddOns', 'billing', 'logOut'],
    },
    {
      name: 'jobOpenings',
      dropdown: ['createJobOpening', 'hiringRequests', 'jobOpeningsList'],
    },
    {
      name: 'trackingBoards',
      link: '/dashboard/tracking-boards',
    },
    {
      name: 'candidates',
      dropdown: ['addCandidate', 'myPool', 'sharedPool', 'interviewsScheduled'],
    },
    {
      name: 'assessments',
      dropdown: ['softHardSkills', 'languages'],
      addOn: 'assessments',
    },
    {
      name: 'analytics',
      dropdown: ['companyHiring', 'hiringMarket'],
      addOn: 'analytics',
    },
    {
      name: 'talexa',
      link: '/dashboard/talexa',
      addOn: 'talexa',
    },
    {
      name: 'help',
      dropdown: ['eLearningVideos', 'clientSupport'],
    },
  ];

  return (
    <header className="dashboard-header">
      <nav>
        <ul className="menu">
          {menuItems.map((item) => (
            (item.addOn ? userAddOns[item.addOn] : true) && (
              <li key={item.name} className="menu-item">
                {item.dropdown ? (
                  <div className="dropdown">
                    <button onClick={() => toggleDropdown(item.name)}>
                      {t(`dashboard.${item.name}`)}
                    </button>
                    {activeDropdown === item.name && (
                      <ul className="dropdown-menu">
                        {item.dropdown.map((subItem) => (
                          <li key={subItem}>
                            {subItem === 'logOut' ? (
                              <button onClick={handleLogout}>
                                {t('dashboard.logOut')}
                              </button>
                            ) : (
                              <Link to={`/dashboard/${item.name.toLowerCase()}/${subItem.toLowerCase()}`}>
                                {t(`dashboard.${subItem}`)}
                              </Link>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ) : (
                  <Link to={item.link}>{t(`dashboard.${item.name}`)}</Link>
                )}
              </li>
            )
          ))}
        </ul>
      </nav>
    </header>
  );
};

export default DashboardHeader;
