import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './JobOpenings.scss';

const JobOpenings = () => {
  const [jobOffers, setJobOffers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterContractType, setFilterContractType] = useState('');
  const [filterHiringManager, setFilterHiringManager] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchJobOffers();
  }, []);

  const fetchJobOffers = async () => {
    try {
      const response = await fetch('/api/job-offers');
      if (response.ok) {
        const data = await response.json();
        setJobOffers(data);
      } else {
        console.error('Failed to fetch job offers');
      }
    } catch (error) {
      console.error('Error fetching job offers:', error);
    }
  };

  const filteredJobOffers = jobOffers.filter(job => 
    job.role.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (filterContractType === '' || job.contract_type === filterContractType) &&
    (filterHiringManager === '' || job.hiring_manager === filterHiringManager)
  );

  const handleCreateClick = () => {
    navigate('/create-job-opening');
  };

  return (
    <div className="job-openings">
      <div className="job-openings-header">
        <h2>Job Openings</h2>
        <button className="create-button" onClick={handleCreateClick}>Create Job Opening</button>
      </div>
      <div className="search-filters">
        <input 
          type="text" 
          placeholder="Search by job title" 
          value={searchTerm} 
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <select 
          value={filterContractType} 
          onChange={(e) => setFilterContractType(e.target.value)}
        >
          <option value="">All Contract Types</option>
          <option value="permanent">Permanent</option>
          <option value="temporary">Temporary</option>
          <option value="contract">Contract</option>
          <option value="internship">Internship</option>
        </select>
        <input 
          type="text" 
          placeholder="Filter by Hiring Manager" 
          value={filterHiringManager} 
          onChange={(e) => setFilterHiringManager(e.target.value)}
        />
      </div>
      <table className="job-offers-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Job Title</th>
            <th>Contract Type</th>
            <th>Role Level</th>
            <th>Created At</th>
            <th>Published At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredJobOffers.map(job => (
            <tr key={job.id}>
              <td>{job.id}</td>
              <td>{job.role}</td>
              <td>{job.contract_type}</td>
              <td>{job.role_level}</td>
              <td>{new Date(job.created_at).toLocaleDateString()}</td>
              <td>{job.published_at ? new Date(job.published_at).toLocaleDateString() : 'Not published'}</td>
              <td>
                <Link to={`/job-offer/${job.id}`} className="details-button">Details</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default JobOpenings;
