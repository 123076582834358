import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './SharedCandidatePool.scss';

const SharedCandidatePool = ({ userAddOns }) => {
  const [candidates, setCandidates] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchSharedCandidates();
  }, []);

  const fetchSharedCandidates = async () => {
    try {
      const response = await fetch('/api/shared-candidates');
      const data = await response.json();
      setCandidates(data);
    } catch (error) {
      console.error('Error fetching shared candidates:', error);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredCandidates = candidates.filter(candidate => {
    const searchLower = searchTerm.toLowerCase();
    return (
      candidate.first_name.toLowerCase().includes(searchLower) ||
      candidate.last_name.toLowerCase().includes(searchLower) ||
      candidate.job_title.toLowerCase().includes(searchLower) ||
      candidate.email.toLowerCase().includes(searchLower) ||
      (userAddOns.add_on_language_assessment && candidate.Languages.some(lang => lang.language.toLowerCase().includes(searchLower)))
    );
  });

  return (
    <div className="shared-candidate-pool">
      <h2>Shared Candidate Pool</h2>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search candidates..."
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>
      <table className="candidates-table">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Job Role</th>
            <th>Email</th>
            <th>Phone</th>
            <th>LinkedIn</th>
            {userAddOns.add_on_language_assessment && <th>Languages</th>}
          </tr>
        </thead>
        <tbody>
          {filteredCandidates.map(candidate => (
            <tr key={candidate.id}>
              <td>{candidate.first_name}</td>
              <td>{candidate.last_name}</td>
              <td>{candidate.job_title}</td>
              <td>{candidate.email}</td>
              <td>{candidate.phone}</td>
              <td><a href={candidate.linkedin_url} target="_blank" rel="noopener noreferrer">LinkedIn Profile</a></td>
              {userAddOns.add_on_language_assessment && <td>{candidate.Languages.map(lang => lang.language).join(', ')}</td>}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SharedCandidatePool;

