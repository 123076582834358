import React, { useState, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { checkAuthStatus } from './services/authService';
import Loading from './components/Loading';
import JobOfferDetails from './components/B2B/B2BDashboard/JobOfferDetails';
import CreateJobOpening from './components/B2B/B2BDashboard/CreateJobOpening';
import Subscription from './components/B2B/B2BDashboard/Subscription';
import Billing from './components/B2B/B2BDashboard/Billing';
import SharedCandidatePool from './components/B2B/B2BDashboard/SharedCandidatePool';
import ScheduledInterviews from './components/B2B/B2BDashboard/ScheduledInterviews';
import JobOpenings from './components/B2B/B2BDashboard/JobOpenings';
import JobBoard from './components/JobBoard';

// Composants chargés de manière différée
const LandingGeneral = lazy(() => import('./components/LandingGeneral'));
const B2BLayout = lazy(() => import('./components/B2B/B2BLayout'));
const B2CLayout = lazy(() => import('./components/B2C/B2CLayout'));
const LandingB2B = lazy(() => import('./components/B2B/LandingB2B'));
const ContactB2B = lazy(() => import('./components/B2B/ContactB2B'));
const TalexaBB = lazy(() => import('./components/B2B/TalexaCompany'));
const TalexaB2C = lazy(() => import('./components/B2C/TalexaCandidate'));
const CompanyB2B = lazy(() => import('./components/B2B/CompanyB2B'));
const LandingB2C = lazy(() => import('./components/B2C/LandingB2C'));
const ContactB2C = lazy(() => import('./components/B2C/ContactB2C'));
const CompanyB2C = lazy(() => import('./components/B2C/CompanyB2C'));
const SoonAvailable = lazy(() => import('./components/SoonAvailable'));
const SignUp = lazy(() => import('./components/SignUpPage'));
const Login = lazy(() => import('./components/LogInPage'));
const PrivacyPolicy = lazy(() => import('./components/FooterPages/PrivacyPolicy'));
const CookiePolicy = lazy(() => import('./components/FooterPages/CookiePolicy'));
const LegalNotice = lazy(() => import('./components/FooterPages/LegalNotice'));
const GeneralTerms = lazy(() => import('./components/FooterPages/GeneralTerms'));
const FAQ = lazy(() => import('./components/FooterPages/FAQ'));
const B2BMyProfile = lazy(() => import('./components/B2B/B2BDashboard/B2BMyProfile'));
const B2BDashboardLayout = lazy(() => import('./components/B2B/B2BDashboard/B2BDashboardLayout'));
const B2CDashboardLayout = lazy(() => import('./components/B2C/B2CDashboard/B2CDashboardLayout'));
const HiringRequestForm = lazy(() => import('./components/B2B/B2BDashboard/HiringRequestForm'));
const HiringRequestDetail = lazy(() => import('./components/B2B/B2BDashboard/HiringRequestDetail'));
const HiringRequestList = lazy(() => import('./components/B2B/B2BDashboard/HiringRequestList'));
const CandidatePool = lazy(() => import('./components/B2B/B2BDashboard/CandidatePool'));
const TalexaB2B = lazy(() => import('./components/B2B/B2BDashboard/TalexaChatB2B'));

console.log('Current NODE_ENV:', process.env.NODE_ENV);

export const API_URL = 'http://localhost:3000';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userAddOns, setUserAddOns] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const verifyAuth = async () => {
      try {
        // Vérifier d'abord le token dans localStorage
        const token = localStorage.getItem('jwt');
        if (!token) {
          setIsAuthenticated(false);
          setLoading(false);
          return;
        }

        const authResult = await checkAuthStatus();
        console.log('Auth check result:', authResult);
        
        setIsAuthenticated(authResult.isAuthenticated);
        setUserAddOns(authResult.userAddOns || {});
      } catch (error) {
        console.error('Auth verification error:', error);
        setIsAuthenticated(false);
      } finally {
        setLoading(false);
      }
    };

    verifyAuth();
  }, []);

  // Route protégée avec vérification de l'authentification
  const ProtectedRoute = ({ children }) => {
    if (loading) {
      return <Loading />;
    }

    if (!isAuthenticated) {
      return <Navigate to="/login" replace />;
    }

    return children;
  };

  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Routes>
          {/* Routes publiques */}
          <Route path="/" element={<LandingGeneral />} />
          <Route path="/job-board" element={<JobBoard />} />
          <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/legal-notice" element={<LegalNotice />} />
          <Route path="/general-terms" element={<GeneralTerms />} />
          <Route path="/faq" element={<FAQ />} />

          {/* Routes B2B - Add the path prefix */}
          <Route path="/b2b" element={<B2BLayout />}>
            <Route path="contact" element={<ContactB2B />} />
            <Route path="talexa" element={<TalexaBB />} />
            <Route path="company" element={<CompanyB2B />} />
            <Route path="home" element={<LandingB2B />} />
          </Route>

          {/* Routes B2C - Add the path prefix */}
          <Route path="/b2c" element={<B2CLayout />}>
            <Route path="contact" element={<ContactB2C />} />
            <Route path="talexa" element={<TalexaB2C />} />
            <Route path="company" element={<CompanyB2C />} />
            <Route path="home" element={<LandingB2C />} />
          </Route>

          {/* Routes protégées B2B */}
          <Route 
            path="/b2b/dashboard/*" 
            element={
              <ProtectedRoute>
                <B2BDashboardLayout userAddOns={userAddOns}>
                  <Routes>
                    <Route path="my-profile" element={<B2BMyProfile isAuthenticated={isAuthenticated} />} />
                    <Route path="subscription" element={<Subscription />} />
                    <Route path="billing" element={<Billing />} />
                    <Route path="candidates" element={<CandidatePool />} />
                    <Route path="talexa" element={<TalexaB2B user={userAddOns} isAuthenticated={isAuthenticated} />} />
                    <Route path="job-openings" element={<JobOpenings />} />
                    <Route path="job-openings/create" element={<CreateJobOpening />} />
                    <Route path="hiring-requests" element={<HiringRequestList />} />
                    <Route path="hiring-requests/:id" element={<HiringRequestDetail />} />
                    <Route path="hiring-requests/new" element={<HiringRequestForm />} />
                    <Route path="shared-candidate-pool" element={<SharedCandidatePool userAddOns={userAddOns} />} />
                    <Route path="scheduled-interviews" element={<ScheduledInterviews />} />
                  </Routes>
                </B2BDashboardLayout>
              </ProtectedRoute>
            } 
          />

          {/* Routes protégées B2C */}
          <Route 
            path="/b2c/dashboard/*" 
            element={
              <ProtectedRoute>
                <B2CDashboardLayout userAddOns={userAddOns} />
              </ProtectedRoute>
            } 
          />

          {/* Autres routes spécifiques */}
          <Route path="/soon-available" element={
            <SoonAvailable />
          } />

          {/* Route pour le formulaire de demande d'embauche */}
          <Route 
            path="/b2b/dashboard/hiring-requests" 
            element={
              <ProtectedRoute>
                <B2BDashboardLayout userAddOns={userAddOns}>
                  <HiringRequestList />
                </B2BDashboardLayout>
              </ProtectedRoute>
            } 
          />

          <Route 
            path="/job-offer/:id" 
            element={
              <ProtectedRoute>
                <B2BDashboardLayout userAddOns={userAddOns}>
                  <JobOfferDetails />
                </B2BDashboardLayout>
              </ProtectedRoute>
            } 
          />

          {/* Route pour la page Billing */}
          <Route 
            path="/b2b/dashboard/billing" 
            element={
              <ProtectedRoute>
                <B2BDashboardLayout userAddOns={userAddOns}>
                  <Billing />
                </B2BDashboardLayout>
              </ProtectedRoute>
            } 
          />

          {/* Route pour le Shared Candidate Pool */}
          <Route 
            path="/b2b/dashboard/shared-candidate-pool" 
            element={
              <ProtectedRoute>
                <B2BDashboardLayout userAddOns={userAddOns}>
                  <SharedCandidatePool userAddOns={userAddOns} />
                </B2BDashboardLayout>
              </ProtectedRoute>
            } 
          />

          {/* Route pour les entretiens programmés */}
          <Route 
            path="/b2b/dashboard/scheduled-interviews" 
            element={
              <ProtectedRoute>
                <B2BDashboardLayout userAddOns={userAddOns}>
                  <ScheduledInterviews />
                </B2BDashboardLayout>
              </ProtectedRoute>
            } 
          />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
