import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './CreateJobOpening.scss';

const CreateJobOpening = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    role: '',
    company_name: '',
    company_description: '',
    cost_center: '',
    contract_type: '',
    employment_type: '',
    mission: '',
    responsibilities: '',
    requirements: '',
    min_year_experience: '',
    role_level: '',
    skills: [],
    start_hour: '',
    end_hour: '',
    city: '',
    is_remote: false,
    is_hybrid: false,
    offer_content: '',
    perks: '',
    min_salary: '',
    max_salary: '',
    hiring_process: '',
    remote: false,
    hybrid: false,
    onsite: false,
    tone: ''
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSkillsChange = (e) => {
    const skills = e.target.value.split(',').map(skill => skill.trim());
    setFormData(prevState => ({ ...prevState, skills }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/job-offers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        navigate('/job-openings');
      } else {
        console.error('Failed to create job offer');
      }
    } catch (error) {
      console.error('Error creating job offer:', error);
    }
  };

  return (
    <div className="create-job-opening">
      <div className="create-job-opening-header">
        <h2>Create Job Opening</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="role">Job Title</label>
          <input type="text" id="role" name="role" value={formData.role} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="company_name">Company Name</label>
          <input type="text" id="company_name" name="company_name" value={formData.company_name} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="company_description">Company Description</label>
          <textarea id="company_description" name="company_description" value={formData.company_description} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="cost_center">Cost Center</label>
          <input type="text" id="cost_center" name="cost_center" value={formData.cost_center} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label htmlFor="contract_type">Contract Type</label>
          <select id="contract_type" name="contract_type" value={formData.contract_type} onChange={handleChange} required>
            <option value="">Select Contract Type</option>
            <option value="full-time">Full Time</option>
            <option value="part-time">Part Time</option>
            <option value="contract">Contract</option>
            <option value="internship">Internship</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="employment_type">Employment Type</label>
          <input type="text" id="employment_type" name="employment_type" value={formData.employment_type} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="mission">Mission</label>
          <textarea id="mission" name="mission" value={formData.mission} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="responsibilities">Responsibilities</label>
          <textarea id="responsibilities" name="responsibilities" value={formData.responsibilities} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="requirements">Requirements</label>
          <textarea id="requirements" name="requirements" value={formData.requirements} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="min_year_experience">Minimum Years of Experience</label>
          <input type="number" id="min_year_experience" name="min_year_experience" value={formData.min_year_experience} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="role_level">Role Level</label>
          <input type="text" id="role_level" name="role_level" value={formData.role_level} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="skills">Skills (comma-separated)</label>
          <input type="text" id="skills" name="skills" value={formData.skills.join(', ')} onChange={handleSkillsChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="start_hour">Start Hour</label>
          <input type="time" id="start_hour" name="start_hour" value={formData.start_hour} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label htmlFor="end_hour">End Hour</label>
          <input type="time" id="end_hour" name="end_hour" value={formData.end_hour} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label htmlFor="city">City</label>
          <input type="text" id="city" name="city" value={formData.city} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label>
            <input type="checkbox" name="is_remote" checked={formData.is_remote} onChange={handleChange} />
            Remote
          </label>
        </div>
        <div className="form-group">
          <label>
            <input type="checkbox" name="is_hybrid" checked={formData.is_hybrid} onChange={handleChange} />
            Hybrid
          </label>
        </div>
        <div className="form-group">
          <label>
            <input type="checkbox" name="onsite" checked={formData.onsite} onChange={handleChange} />
            Onsite
          </label>
        </div>
        <div className="form-group">
          <label htmlFor="offer_content">Offer Content</label>
          <textarea id="offer_content" name="offer_content" value={formData.offer_content} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="perks">Perks</label>
          <textarea id="perks" name="perks" value={formData.perks} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label htmlFor="min_salary">Minimum Salary</label>
          <input type="number" id="min_salary" name="min_salary" value={formData.min_salary} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="max_salary">Maximum Salary</label>
          <input type="number" id="max_salary" name="max_salary" value={formData.max_salary} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="hiring_process">Hiring Process</label>
          <textarea id="hiring_process" name="hiring_process" value={formData.hiring_process} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label htmlFor="tone">Tone</label>
          <input type="text" id="tone" name="tone" value={formData.tone} onChange={handleChange} />
        </div>
        <button type="submit">Create Job Opening</button>
      </form>
    </div>
  );
};

export default CreateJobOpening;
