import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { getJobBoard, getSearchJobs } from '../services/jobOfferService';
import FooterMenuGeneral from './FooterMenuGeneral';
import B2BDashboardHeader from './B2B/B2BDashboard/B2BDashboardHeader';
import B2CDashboardHeader from './B2C/B2CDashboard/B2CDashboardHeader';
import logo from '../assets/Logo.png';
import './JobBoard.scss';

// Import flag images
import ukFlag from '../assets/flags/en-flag.png';
import frFlag from '../assets/flags/fr-flag.png';
import esFlag from '../assets/flags/es-flag.png';
import itFlag from '../assets/flags/it-flag.png';
import ptFlag from '../assets/flags/pt-flag.png';
import deFlag from '../assets/flags/de-flag.png';

const JobBoard = () => {
  const [jobOffers, setJobOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isLangMenuOpen, setIsLangMenuOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userType, setUserType] = useState(null);

  // États pour les filtres de recherche
  const [searchQuery, setSearchQuery] = useState('');
  const [contractType, setContractType] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');

  useEffect(() => {
    fetchJobOffers();
    checkAuthStatus();
  }, []);

  const checkAuthStatus = () => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    if (user) {
      setIsAuthenticated(true);
      setUserType(user.role); 
    }
  };

  const fetchJobOffers = async () => {
    try {
      console.log('Fetching job offers...');
      const response = await getJobBoard();
      console.log('API response:', response);
      if (Array.isArray(response)) {
        setJobOffers(response);
      } else if (response.message) {
        setJobOffers([]);
        setError(response.message);
      }
      setLoading(false);
    } catch (err) {
      console.error('Error fetching job offers:', err);
      setError(t('errorFetchingJobOffers'));
      setLoading(false);
    }
  };

  const handleSearch = async () => {
    setLoading(true);
    try {
      const searchParams = new URLSearchParams({
        query: searchQuery,
        contractType,
        location: city || country 
      });
      const offers = await getSearchJobs(searchParams);
      setJobOffers(offers);
    } catch (err) {
      setError(t('errorSearchingJobOffers'));
    } finally {
      setLoading(false);
    }
  };

  const toggleLangMenu = () => {
    setIsLangMenuOpen(!isLangMenuOpen);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setIsLangMenuOpen(false);
  };

  const getCurrentLanguageFlag = () => {
    switch (i18n.language) {
      case 'fr': return frFlag;
      case 'es': return esFlag;
      case 'it': return itFlag;
      case 'pt': return ptFlag;
      case 'de': return deFlag;
      default: return ukFlag;
    }
  };

  if (loading) return <div className="loading">{t('loading')}</div>;
  if (error) return <div className="error">{error}</div>;
  console.log('pute');

  return (
    <div className="job-board-page">
      {isAuthenticated ? (
        userType === 'company' ? (
          <B2BDashboardHeader userAddOns={{}} />
        ) : (
          <B2CDashboardHeader userAddOns={{}} />
        )
      ) : (
        <header className="header-nav">
          <div className="logo-container">
            <Link to="/" className="logo-link">
              <img src={logo} alt="Logo" className="logo" />
            </Link>
          </div>
          <ul className="nav-links">
            <li className="language-selector">
              <button onClick={toggleLangMenu} className="lang-toggle">
                <img src={getCurrentLanguageFlag()} alt={i18n.language} className="flag-icon" />
                <span className="current-lang">{i18n.language.toUpperCase()}</span>
              </button>
              <ul className={`lang-dropdown ${isLangMenuOpen ? 'open' : ''}`}>
                <li><button onClick={() => changeLanguage('en')}><img src={ukFlag} alt="EN" />EN</button></li>
                <li><button onClick={() => changeLanguage('fr')}><img src={frFlag} alt="FR" />FR</button></li>
                <li><button onClick={() => changeLanguage('es')}><img src={esFlag} alt="ES" />ES</button></li>
                <li><button onClick={() => changeLanguage('it')}><img src={itFlag} alt="IT" />IT</button></li>
                <li><button onClick={() => changeLanguage('pt')}><img src={ptFlag} alt="PT" />PT</button></li>
                <li><button onClick={() => changeLanguage('de')}><img src={deFlag} alt="DE" />DE</button></li>
              </ul>
            </li>
          </ul>
        </header>
      )}

      <main className="job-board">
        <h1>{t('jobBoard')}</h1>
        
        <div className="search-filters">
          <input
            type="text"
            placeholder={t('searchKeywords')}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <select value={contractType} onChange={(e) => setContractType(e.target.value)}>
            <option value="">{t('allContractTypes')}</option>
            <option value="permanent">{t('permanent')}</option>
            <option value="fixed-term">{t('fixedTerm')}</option>
            <option value="temporary">{t('temporary')}</option>
          </select>
          <input
            type="text"
            placeholder={t('city')}
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
          <input
            type="text"
            placeholder={t('country')}
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          />
        </div>
        <div className="job-list">
          {jobOffers.length > 0 ? (
            jobOffers.map((job) => (
              <div key={job.id} className="job-card">
                <h2>{job.role}</h2>
                <p className="company">{job.company_name}</p>
                <p className="contract-type">{t(job.contract_type)}</p>
                <p className="location">{`${job.city || t('notSpecified')}, ${job.country || t('notSpecified')}`}</p>
                <p className="created-at">{new Date(job.created_at).toLocaleDateString()}</p>
              </div>
            ))
          ) : (
            <div className="no-jobs-message">
              {t('noJobOffersToDisplay')}
              <span>{t('checkBackLater')}</span>
            </div>
          )}
        </div>
        <Outlet />
      </main>
      <FooterMenuGeneral />
    </div>
  );
};

export default JobBoard;
