import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './B2BDashboardHeader.scss';
import { logError } from '../../../services/errorLogging'; // Importez la fonction logError

const DashboardHeader = ({ userAddOns }) => {
  const { t } = useTranslation();
  const [activeDropdown, setActiveDropdown] = useState(null);
  const navigate = useNavigate();

  const toggleDropdown = (menu) => {
    try {
      setActiveDropdown(prevState => prevState === menu ? null : menu);
    } catch (error) {
      logError(error); // Utilisez logError ici
    }
  };

  const handleLogout = () => {
    try {
      // Supprimez les informations de l'utilisateur du sessionStorage
      sessionStorage.removeItem('user');
      // Supprimez le token d'authentification (si vous en utilisez un)
      localStorage.removeItem('authToken');
      // Redirigez l'utilisateur vers la page de connexion
      navigate('/login');
    } catch (error) {
      logError(error);
    }
  };

  const handleNavigation = (path) => {
    console.log("Navigating to:", path);
    navigate(path);
  };

  const menuItems = [
    {
      name: 'myAccount',
      dropdown: [
        { 
          name: 'myProfile', 
          onClick: () => handleNavigation('/b2b/dashboard/my-profile')
        },
        { 
          name: 'subscription', 
          onClick: () => handleNavigation('/b2b/dashboard/subscription')
        },
        { 
          name: 'billing', 
          onClick: () => handleNavigation('/b2b/dashboard/billing')
        },
        { name: 'logOut', action: handleLogout }
      ]
    },
    {
      name: 'jobOpenings',
      dropdown: [
        { name: 'createJobOpening', link: '/b2b/dashboard/job-openings/create' },
        { name: 'hiringRequests', link: '/b2b/dashboard/hiring-requests' },
        { name: 'jobOpeningsList', link: '/b2b/dashboard/job-openings' }
      ]
    },
    {
      name: 'trackingBoards',
      link: '/b2b/dashboard/tracking-boards'
    },
    {
      name: 'candidates',
      dropdown: [
        { name: 'addCandidate', link: '/b2b/dashboard/candidates/add' },
        { name: 'myPool', link: '/b2b/dashboard/candidates' },
        { name: 'sharedPool', link: '/b2b/dashboard/shared-candidate-pool' },
        { name: 'emailTemplates', link: '/b2b/dashboard/email-templates' },
        { name: 'interviewsScheduled', link: '/b2b/dashboard/scheduled-interviews' } 
      ]
    },
    {
      name: 'assessments',
      dropdown: ['softHardSkills', 'languages'],
      isAddOn: true,
      addOnKey: 'assessments'
    },
    {
      name: 'analytics',
      dropdown: ['companyHiring', 'hiringMarket'],
      isAddOn: true,
      addOnKey: 'analytics'
    },
    {
      name: 'talexa',
      onClick: () => handleNavigation('/b2b/dashboard/talexa')
    },
    {
      name: 'jobBoard',
      link: '/job-board'
    },
    {
      name: 'help',
      dropdown: ['eLearningVideos', 'clientSupport']
    }
  ];

  return (
    <header className="dashboard-header">
      <nav>
        <ul className="menu">
          {menuItems.map((item) => {
            const shouldDisplay = item.isAddOn ? userAddOns[item.addOnKey] : true;

            return shouldDisplay ? (
              <li key={item.name} className={`menu-item ${item.dropdown ? 'has-dropdown' : ''}`}>
                {item.dropdown ? (
                  <>
                    <button 
                      className="menu-link dropdown-toggle"
                      onClick={() => toggleDropdown(item.name)}
                      aria-haspopup="true"
                      aria-expanded={activeDropdown === item.name}
                    >
                      {t(`dashboard.${item.name}`)}
                    </button>
                    <ul className={`dropdown-menu ${activeDropdown === item.name ? 'active' : ''}`}>
                      {item.dropdown.map((subItem) => (
                        <li key={subItem.name}>
                          {subItem.action ? (
                            <button onClick={subItem.action} className="dropdown-item">
                              {t(`dashboard.${subItem.name}`)}
                            </button>
                          ) : (
                            <Link to={subItem.link} className="dropdown-item">
                              {t(`dashboard.${subItem.name}`)}
                            </Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  </>
                ) : (
                  item.onClick ? (
                    <button 
                      className="menu-link"
                      onClick={item.onClick}
                    >
                      {t(`dashboard.${item.name}`)}
                    </button>
                  ) : (
                    <Link 
                      to={item.link} 
                      className="menu-link"
                    >
                      {t(`dashboard.${item.name}`)}
                    </Link>
                  )
                )}
              </li>
            ) : null;
          })}
        </ul>
      </nav>
    </header>
  );
};

export default DashboardHeader;
