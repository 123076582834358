import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import './JobOfferDetails.scss';

const JobOfferDetails = () => {
  const { id } = useParams();
  const [jobOffer, setJobOffer] = useState(null);

  useEffect(() => {
    fetchJobOfferDetails();
  }, [id]);

  const fetchJobOfferDetails = async () => {
    try {
      const response = await fetch(`/api/job-offers/${id}`);
      if (response.ok) {
        const data = await response.json();
        setJobOffer(data);
      } else {
        console.error('Failed to fetch job offer details');
      }
    } catch (error) {
      console.error('Error fetching job offer details:', error);
    }
  };

  if (!jobOffer) {
    return <div className="job-offer-details">Loading...</div>;
  }

  return (
    <div className="job-offer-details">
      <h2>{jobOffer.role} at {jobOffer.company_name}</h2>
      <div className="job-offer-section">
        <h3>Company Information</h3>
        <p><strong>Company Name:</strong> {jobOffer.company_name}</p>
        <p><strong>Company Description:</strong> {jobOffer.company_description}</p>
        <p><strong>Cost Center:</strong> {jobOffer.cost_center}</p>
      </div>
      <div className="job-offer-section">
        <h3>Job Details</h3>
        <p><strong>Contract Type:</strong> {jobOffer.contract_type}</p>
        <p><strong>Employment Type:</strong> {jobOffer.employment_type}</p>
        <p><strong>Role Level:</strong> {jobOffer.role_level}</p>
        <p><strong>Minimum Years of Experience:</strong> {jobOffer.min_year_experience}</p>
        <p><strong>Work Hours:</strong> {jobOffer.start_hour} - {jobOffer.end_hour}</p>
        <p><strong>Location:</strong> {jobOffer.city}</p>
        <p><strong>Remote:</strong> {jobOffer.is_remote ? 'Yes' : 'No'}</p>
        <p><strong>Hybrid:</strong> {jobOffer.is_hybrid ? 'Yes' : 'No'}</p>
        <p><strong>Onsite:</strong> {jobOffer.onsite ? 'Yes' : 'No'}</p>
      </div>
      <div className="job-offer-section">
        <h3>Job Description</h3>
        <p><strong>Mission:</strong> {jobOffer.mission}</p>
        <p><strong>Responsibilities:</strong> {jobOffer.responsibilities}</p>
        <p><strong>Requirements:</strong> {jobOffer.requirements}</p>
      </div>
      <div className="job-offer-section">
        <h3>Skills</h3>
        <ul>
          {jobOffer.skills.map((skill, index) => (
            <li key={index}>{skill}</li>
          ))}
        </ul>
      </div>
      <div className="job-offer-section">
        <h3>Compensation</h3>
        <p><strong>Salary Range:</strong> ${jobOffer.min_salary} - ${jobOffer.max_salary}</p>
        <p><strong>Perks:</strong> {jobOffer.perks}</p>
      </div>
      <div className="job-offer-section">
        <h3>Additional Information</h3>
        <p><strong>Hiring Process:</strong> {jobOffer.hiring_process}</p>
        <p><strong>Offer Content:</strong> {jobOffer.offer_content}</p>
        <p><strong>Tone:</strong> {jobOffer.tone}</p>
      </div>
      <div className="job-offer-section">
        <h3>Metadata</h3>
        <p><strong>Created At:</strong> {new Date(jobOffer.created_at).toLocaleString()}</p>
        <p><strong>Updated At:</strong> {new Date(jobOffer.updated_at).toLocaleString()}</p>
      </div>
      <Link to="/job-openings" className="back-button">Back to Job Openings</Link>
    </div>
  );
};

export default JobOfferDetails;

