import React from 'react';
import { Link } from 'react-router-dom';
import { FaLinkedin } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import './FooterMenuGeneral.scss';
import './Variables.scss';
import logo from '../assets/Logo.png'; // Make sure to add your logo file

const FooterMenuGeneral = () => {
  const { t } = useTranslation();

  // Define an array of policy objects with their respective routes
  const policies = [
    { key: 'privacy', route: '/privacy-policy' },
    { key: 'cookies', route: '/cookie-policy' },
    { key: 'terms', route: '/terms' },
    { key: 'legal', route: '/legal-notice' }
  ];

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-left">
          <div className="logo-and-features">
            <img src={logo} alt="HRnov AI Logo" className="footer-logo" />
            <ul className="footer-features">
              <li>{t('aiDrivenRecruitment')}</li>
              <li>{t('inclusiveHiringTechnology')}</li>
              <li>{t('talentMatchingInnovation')}</li>
            </ul>
          </div>
        </div>
        <div className="footer-right">
          <ul className="footer-links">
            <li>
              <a href="https://www.linkedin.com/company/hrnov-ai" target="_blank" rel="noopener noreferrer">
                <FaLinkedin /> 
              </a>
            </li>
            <li><Link to="/faq">FAQ</Link></li>
            <li><Link to="/cookie-policy">Cookie Policy</Link></li>
            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            <li><Link to="/general-terms">General Terms</Link></li>
            <li><Link to="/legal-notice">Legal Notice</Link></li>
          </ul>
        </div>
      </div>
      <div className="footer-copyright">
        <p>&copy; 2024 HRnov AI - {t("footer.allRightsReserved")}</p>
      </div>
    </footer>
  );
};

export default FooterMenuGeneral;
