import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './ScheduledInterviews.scss';

const ScheduledInterviews = () => {
  const [upcomingInterviews, setUpcomingInterviews] = useState([]);
  const [pastInterviews, setPastInterviews] = useState([]);
  const [customEmails, setCustomEmails] = useState({});

  useEffect(() => {
    fetchInterviews();
    fetchCustomEmails();
  }, []);

  const fetchInterviews = async () => {
    try {
      const response = await fetch('/api/interviews');
      const data = await response.json();
      
      const now = new Date();
      setUpcomingInterviews(data.filter(interview => new Date(interview.date) > now));
      setPastInterviews(data.filter(interview => new Date(interview.date) <= now));
    } catch (error) {
      console.error('Error fetching interviews:', error);
    }
  };

  const fetchCustomEmails = async () => {
    try {
      const response = await fetch('/api/interviews/custom-emails');
      const data = await response.json();
      setCustomEmails(data);
    } catch (error) {
      console.error('Error fetching custom emails:', error);
    }
  };

  const renderInterviewList = (interviews, isPast = false) => (
    <table className="interviews-table">
      <thead>
        <tr>
          <th>Date & Time</th>
          <th>Candidate</th>
          <th>Job Title</th>
          <th>Video Link</th>
          <th>Actions</th>
          <th>Custom Emails</th>
        </tr>
      </thead>
      <tbody>
        {interviews.map(interview => (
          <tr key={interview.id}>
            <td>{new Date(interview.date).toLocaleString()}</td>
            <td>
              <Link to={`/candidate/${interview.candidate.id}`}>
                {interview.candidate.first_name} {interview.candidate.last_name}
              </Link>
            </td>
            <td>
              <Link to={`/job-offer/${interview.jobOffer.id}`}>
                {interview.jobOffer.title}
              </Link>
            </td>
            <td>
              <a href={interview.videoLink} target="_blank" rel="noopener noreferrer">
                Join Video Call
              </a>
            </td>
            <td>
              <Link to={`/candidate/${interview.candidate.id}/cv`}>View CV</Link>
              {interview.candidate.linkedinUrl && (
                <a href={interview.candidate.linkedinUrl} target="_blank" rel="noopener noreferrer">
                  LinkedIn
                </a>
              )}
              <Link to={`/candidate/${interview.candidate.id}/talexa-profile`}>Talexa Profile</Link>
              <Link to={`/interview/${interview.id}/questionnaire`}>Interview Questionnaire</Link>
              {customEmails[interview.id] && customEmails[interview.id].length > 0 ? (
                <button onClick={() => showCustomEmails(interview.id)}>
                  View Custom Emails ({customEmails[interview.id].length})
                </button>
              ) : (
                'No custom emails'
              )}
              {!isPast && (
                <button onClick={() => sendCustomEmail(interview.id)}>Send Custom Email</button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const sendCustomEmail = async (interviewId) => {
    try {
      const emailContent = prompt('Enter the content of your custom email:');
      if (emailContent) {
        const response = await fetch(`/api/interviews/${interviewId}/send-email`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ content: emailContent }),
        });
        if (response.ok) {
          alert('Custom email sent successfully');
          fetchCustomEmails(); // Refresh the list of custom emails
        } else {
          throw new Error('Failed to send custom email');
        }
      }
    } catch (error) {
      console.error('Error sending custom email:', error);
      alert('Failed to send custom email');
    }
  };

  return (
    <div className="scheduled-interviews">
      <h2>Scheduled Interviews</h2>
      <h3>Upcoming Interviews</h3>
      {renderInterviewList(upcomingInterviews)}
      <h3>Past Interviews</h3>
      {renderInterviewList(pastInterviews, true)}
    </div>
  );
};

export default ScheduledInterviews;
